/**
 * @module builder
 */
import type { OptionsDocument, QuoteDocument } from 'paintscout'

import type { QuoteItemSection } from '../../index'
import { moveArea } from '../../areas/move-area'
import { getAreaSubstrateArea } from '../../area-substrates/get-area-substrate-area'
import { moveTo, calculateQuote, getQuoteOptions } from '../../util'
import { moveAreaSubstrate } from '../../area-substrates/move-area-substrate'
import type { RenderableSubItem } from '../../items'
import getSubstrateAreas from '../../items/get-items/util/get-substrate-areas'

export function moveGroup(args: {
  quote: QuoteDocument
  options: OptionsDocument
  key: string
  section: QuoteItemSection
  previousSection: QuoteItemSection
  calculate?: boolean
}): QuoteDocument {
  const { key, section, options, calculate, previousSection } = args
  const quote: any = { ...args.quote }

  if (!quote.groups[key]) {
    return { ...quote }
  }

  const group = { ...quote.groups[key] }
  const quoteOptions = getQuoteOptions({ quote, options })

  /* We need to move all of the groups children first, then the group */
  let updatedQuote = group.children.reduce(
    (acc: QuoteDocument, child: string) => {
      if (quote.areas[child]) {
        acc = moveArea({ quote: acc, options, key: child, section, previousSection, moveSubstrates: true })
      } else if (quote.substrates[child]) {
        // We will process substrateChildren below for version 5 and above quotes
        // Should be faster to improve laggyness on large quotes
        if (quote.version >= 5) return acc

        // Must move the areaSubstrates, not the substrate, it will get regen'd in calculateQuote
        const substrateAreas = getSubstrateAreas({
          quote: acc,
          options: quoteOptions,
          clientOptions: options,
          substrateKey: child,
          section: group.section,
          showValues: true,
          view: 'substrate',
          showGroup: true,
          groupKey: group.key
        })
        acc = substrateAreas.reduce(
          (acc: QuoteDocument, child: RenderableSubItem) => {
            return moveAreaSubstrate({
              quote: acc,
              options,
              areaKey: child.additionalFields.areaKey,
              key: child.key,
              section,
              calculate: false
            })
          },
          { ...acc }
        )
        return acc
      } else if (quote.lineItems[child]) {
        return {
          ...acc,
          lineItems: {
            ...acc.lineItems,
            [child]: moveTo(quote.lineItems[child], section)
          }
        }
      }
      return acc
    },
    { ...quote }
  )

  if (quote.version >= 5 && group?.substrateChildren?.length) {
    updatedQuote = group.substrateChildren.reduce(
      (acc: QuoteDocument, child: string, _i) => {
        const area = getAreaSubstrateArea({ quote: acc, substrateKey: child })
        acc = moveAreaSubstrate({
          quote: acc,
          options,
          areaKey: area.key,
          key: child,
          section,
          calculate: false
        })

        return acc
      },
      { ...updatedQuote }
    )
  }

  // Need to update group section after its children so they can be correctly found and moved
  updatedQuote = {
    ...updatedQuote,
    groups: {
      ...quote.groups,
      [key]: {
        ...group,
        section
      }
    }
  }

  return calculate ? calculateQuote({ quote: updatedQuote, options }) : updatedQuote
}
