import { makeStyles } from '@material-ui/core'
import { useViewQuoteQuery } from '@paintscout/api'
import * as Sentry from '@sentry/react'
import {
  AnimatedLogo,
  ClientOptionsProvider,
  CustomCss,
  DialogStackProvider,
  ThemeProvider,
  useClientOptions,
  useUser
} from '@ui/paintscout'
import type { QuoteView } from '@ui/react-quote'
import { QuoteContextProvider } from '@ui/react-quote'
import type { Payment, PresentationEstimator, QuoteDocument } from 'paintscout'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { parseUrl } from 'src/util/parseUrl'

import type { QuoteItemSection } from '@paintscout/util/builder'
import { getFeatures, moveItems } from '@paintscout/util/builder'
import type { WisetackPromoResponse } from '@ui/react-quote/src/Wisetack'
import { useWisetack } from '@ui/react-quote/src/Wisetack'
import { useLocation } from 'react-router-dom'
import { useQuoteResponse } from '../hooks'
import getTheme from '../util/getTheme'
import ErrorMessage from './ErrorMessage'

export interface ViewContextValue {
  url?: string
  email?: string
  pdf?: boolean
  revision?: boolean
  estimator?: PresentationEstimator
  silent?: boolean
  companyId?: string
  loading?: boolean
  view?: QuoteView
  payment?: Payment
}

const useStyles = makeStyles({
  loading: {
    height: '100vh',
    color: '#757575',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
})

const ViewContext = React.createContext<ViewContextValue>({})

/**
 * Loads & provides appropriate information for the view via the url
 *
 * this is based on the viewQuote query so it also provides the QuoteContextProvider. This may feel gross, but the entire
 * customer-view is based on the quote anyway. Eventually we might have a more domain-based customer view,
 * in which case we could move the quote fetching elsewhere and use a more generic "get view info" query here.
 */
export default function ViewProvider({ children }: { children: any }) {
  const classes = useStyles({})

  const location = useLocation()

  const { url, silent, email = '', revision, pdf, source, paymentId } = parseUrl(location.search)
  const skip = !url
  const retried = useRef<boolean>(false)
  const { loading, data, error, refetch } = useViewQuoteQuery({
    variables: {
      silent: silent === 'true' || silent === '1',
      pdf: pdf === 'true' || pdf === '1',
      revision: revision === 'true' || revision === '1',
      email: encodeURIComponent(email),
      url
    },
    skip: skip
  })

  const { quote: urlQuote, options, estimator, companyId, view, decryptedEmail } = data?.viewQuote ?? {}
  const [quote, setQuote] = useState<QuoteDocument>(urlQuote)

  const { paymentRequests: paymentRequestsFeature } = getFeatures({ options })

  useEffect(() => {
    if (!quote && urlQuote) {
      setQuote(urlQuote)
    }
  }, [quote, urlQuote])

  const payment =
    view === 'payment-receipt' && !!paymentRequestsFeature?.enabled && !!paymentId
      ? quote?.payments?.find((pr) => pr.id === paymentId)
      : undefined

  Sentry.setTags({
    companyId,
    view
  })

  const theme = useMemo(() => getTheme(options), [options])

  const value = useMemo(
    () => ({
      url,
      silent: silent === 'true' || silent === '1',
      pdf: pdf === 'true' || pdf === '1',
      revision: revision === 'true' || revision === '1',
      email: decodeURIComponent(decryptedEmail) ?? '',
      estimator,
      loading,
      view: view as QuoteView,
      payment,
      companyId
    }),
    // eslint-disable-next-line
    [data, loading, email, silent, url, view, pdf, quote, estimator, decryptedEmail, companyId]
  )

  const LoadingMessage = (
    <div className={classes.loading}>
      <AnimatedLogo scale={0.3} />
    </div>
  )

  const LocationErrorMessage = (
    <ErrorMessage
      title="Whoops"
      description="We weren't able to locate your estimate. Please contact your estimator."
    />
  )

  // I don't know if this is a good idea... Speculating that there is a render between when `setQuote` is
  // called and when the quote is actually set, which slips through and "looks" like a failed payment request load.
  if (loading || (!quote && !error)) {
    return LoadingMessage
  }

  if (skip) {
    console.log({
      location: JSON.stringify(location, null, 2),
      referrer: document?.referrer,
      href: window.location?.href
    })
    Sentry.captureMessage('Unable to load quote (no url provided)', {
      level: 'warning' as Sentry.SeverityLevel,
      tags: {
        url,
        pdf,
        view,
        silent
      }
    })

    return LocationErrorMessage
  }

  if (quote?.trashed) {
    Sentry.captureMessage('Unable to load quote (trashed)', {
      level: 'info' as Sentry.SeverityLevel,
      tags: {
        url,
        pdf,
        view,
        silent
      }
    })
    return LocationErrorMessage
  }

  if (view === 'payment-receipt' && (error || !payment)) {
    console.log(JSON.stringify({ 'location.search': location.search }))
    console.log(JSON.stringify({ paymentId }))
    console.log(JSON.stringify({ error }))
    console.log(JSON.stringify({ 'quote.payments': quote?.payments }))
    console.log({ quote })
    console.log(JSON.stringify({ payment }))
    console.log(JSON.stringify(data))
    console.log(JSON.stringify({ loading, email, silent, url, view, pdf, quote, estimator, decryptedEmail }))
    Sentry.captureException(error ?? 'Unable to load payment receipt', {
      tags: {
        url,
        view,
        quoteId: quote?._id,
        paymentId
      }
    })
    return (
      <ErrorMessage
        title="Looks like this receipt is no longer available!"
        variant="warning"
        description={`Please contact ${
          options?.options?.companyName?.value ?? 'your estimator'
        } if you have any questions.`}
      />
    )
  }

  if (error) {
    if ((error.message ?? '').includes('Quote Unavailable')) {
      Sentry.captureMessage('Unable to load quote (unavailable)', {
        level: 'info' as Sentry.SeverityLevel,
        tags: {
          url,
          pdf,
          view,
          silent
        }
      })
      return (
        <ErrorMessage
          title="Whoops"
          description="This estimate is no longer accessible. Please contact your estimator."
        />
      )
    } else if ((error.message ?? '').includes('Quote Expired')) {
      Sentry.captureMessage('Unable to load quote (expired)', {
        level: 'info' as Sentry.SeverityLevel,
        tags: {
          url,
          pdf,
          view,
          silent
        }
      })
      return (
        <ErrorMessage
          title="Whoops"
          description="Your estimate has expired. Please ask your estimator to send again."
        />
      )
    } else if ((error.message ?? '').includes('400')) {
      Sentry.captureMessage(`Unable to load quote (400, ${source ?? 'none'})`, {
        level: 'error' as Sentry.SeverityLevel,
        tags: {
          url,
          pdf,
          view,
          silent
        }
      })
    } else {
      if (!retried.current) {
        retried.current = true
        refetch()
        return LoadingMessage
      } else {
        Sentry.captureMessage('Unable to load quote (error)', {
          level: 'warning' as Sentry.SeverityLevel,
          tags: {
            url,
            pdf,
            view,
            silent
          }
        })
      }
    }

    return LocationErrorMessage
  }

  return (
    <ViewContext.Provider value={value}>
      <ThemeProvider theme={theme}>
        <DialogStackProvider>
          <ClientOptionsProvider options={options} clientId={companyId}>
            <CustomCss />
            <QuoteContextWrapper
              estimator={estimator}
              quote={quote ?? urlQuote}
              setQuote={setQuote}
              refetchQuote={refetch}
            >
              {children}
            </QuoteContextWrapper>
          </ClientOptionsProvider>
        </DialogStackProvider>
      </ThemeProvider>
    </ViewContext.Provider>
  )
}

function QuoteContextWrapper({ estimator, quote, children, setQuote, refetchQuote }) {
  const { acceptAdditionalWork, acceptQuote } = useQuoteResponse()

  // wisetack promo
  const { options } = useClientOptions()
  const { user, preferences } = useUser()
  const { getPromo } = useWisetack({ options, user, preferences })
  const [wisetackPromo, setWisetackPromo] = useState<WisetackPromoResponse>()

  useEffect(() => {
    const updatePromo = async () => {
      setWisetackPromo(await getPromo(quote))
    }
    updatePromo()
    // eslint-disable-next-line
  }, [quote?.totals?.balance_due])

  const handleItemAction = (
    action: 'move-to-added-options' | 'make-option',
    section: QuoteItemSection,
    keys: string[]
  ) => {
    const updatedQuote = moveItems({
      quote,
      options,
      keys,
      previousSection: section,
      section: action === 'make-option' ? 'options' : 'added-options'
    })

    setQuote(updatedQuote)
  }

  return (
    <QuoteContextProvider
      estimator={estimator}
      quote={quote}
      onAccept={async (pending?: boolean, argsQuote?: QuoteDocument) => {
        // pending includes a check for if the quote is accepted
        if (pending) {
          setQuote(await acceptAdditionalWork({ quote: argsQuote ?? quote }))
        } else {
          setQuote(await acceptQuote({ quote: argsQuote ?? quote }))
        }
      }}
      isCustomerView
      isPresentation
      contextValue={{ wisetackPromo, onItemAction: handleItemAction, updateQuote: ({ quote }) => setQuote(quote) }}
      refetchQuote={async () => {
        const freshQuoteView = await refetchQuote()
        if (freshQuoteView?.data?.viewQuote?.quote) {
          setQuote(freshQuoteView.data.viewQuote.quote)
        }
      }}
    >
      {children}
    </QuoteContextProvider>
  )
}

export function useView() {
  return useContext(ViewContext)
}
