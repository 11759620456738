import type { Theme } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core'
import {
  Presentation,
  PresentationNavContextProvider,
  PresentationNavFull,
  PresentationNavMobile,
  PresentationNavSide,
  useQuote,
  PaymentRequests,
  PaymentReceipt
} from '@ui/react-quote'
import CollectStripePaymentDialog from '@ui/react-quote/src/dialogs/CollectStripePaymentDialog'
import CollectPayrixPaymentDialog from '@ui/react-quote/src/dialogs/CollectPayrixPaymentDialog'
import { Alert, useClientOptions, Hidden, useDialogs, useMediaQuery } from '@ui/paintscout'
import {
  getDefaultPresentation,
  getFeature,
  getItems,
  getObjectLabels,
  getQuoteOptions,
  getQuotePresentation,
  isExpired
} from '@paintscout/util/builder'
import React, { useEffect, useState } from 'react'
import ErrorMessage from 'src/components/ErrorMessage'
import IdleTimeout from 'src/components/IdleTimeout'
import ChatButton from '../components/ChatButton'
import Footer from '../components/Footer'

import QuoteHeartbeat from '../components/QuoteHeartbeat'
import { useView } from '../components/ViewProvider'
import { useDownloadQuote, useQuoteResponse } from '../hooks'
import type { PayrixPaymentType } from 'paintscout'

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      marginTop: theme.paintscout.header.height.md,
      [theme.breakpoints.down('sm')]: {
        padding: 0
      },
      '@media print': {
        paddingTop: 0,
        marginTop: 0
      }
    },
    mainLayout: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'center',

      margin: `0 auto`,

      width: 1150,
      [theme.breakpoints.down('lg')]: {
        width: 'auto'
      },
      '@media print': {
        width: 'auto',
        display: 'block'
      }
    },
    sideNavWrapper: {
      width: 175,
      marginTop: theme.spacing(4),
      marginRight: theme.spacing(2),
      '@media print': {
        display: 'none'
      }
    },
    sideNav: {
      width: 165,
      position: 'fixed'
    },
    presentationWrapper: {
      paddingBottom: 20,
      width: 960,
      [theme.breakpoints.down('lg')]: {
        width: '100%'
      },
      [theme.breakpoints.down('sm')]: {},
      '@media print': {
        width: 'auto',
        padding: 0
      }
    },
    loading: {
      height: '100vh',
      color: '#757575',
      width: '100vw',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    simpleQuoteWrapper: {
      marginTop: '2rem'
    },
    alert: {
      marginBottom: 0,
      marginTop: '1rem'
    }
  }
})

export default function Index() {
  const classes = useStyles({})

  const { view, pdf, silent, revision, payment } = useView()
  const quoteContext = useQuote()
  const { quote, onAccept } = quoteContext

  const { options, clientId } = useClientOptions()
  const objectLabels = getObjectLabels({ options, quote, invoice: quote.is_invoice })
  const { allowPaymentsOnInvoices, allowACHPaymentsOnInvoices, allowedDepositTypes } = getQuoteOptions({
    quote,
    options
  })

  const { openDialog, dismissDialog } = useDialogs()

  const { downloadQuote } = useDownloadQuote()
  const { declineQuote, responding } = useQuoteResponse()
  const isQuoteExpired = isExpired({ quote, options, view })

  const theme = useTheme()

  const presentation = getQuotePresentation({ quote, options, view })

  /*
    Start the collapsable nav menu open ONLY if we're on a small device.
    This probably isn't necessary, but helps a little bit if a person is to 
    shrink their window after loading.
    A caveat to this is on Work Orders & Invoices: Presumably it would be
    annoying to have it auto open the menu on those.
  */
  const lgDown = useMediaQuery('md')
  const [pauseHeartbeat, setPauseHeartbeat] = useState(false)
  const doHeartbeat = !['work-order', 'product-order-form'].includes(view) && !pdf && !pauseHeartbeat
  const doIdleTimeout = !['work-order', 'product-order-form'].includes(view)

  const autoOpenOption = presentation.advanced?.autoOpenNav
  const autoOpen =
    lgDown && view !== 'work-order' && view !== 'product-order-form' && !quote?.is_invoice && !pdf && autoOpenOption
  const [navOpen, setNavOpen] = useState(autoOpen)

  const [currentPage, setCurrentPage] = useState<string>(presentation.pages[0].key)

  const stacked = pdf || presentation?.advanced?.infiniteScroll

  useEffect(() => {
    if (options) {
      document.title = `${options.options.companyName.value} | ${objectLabels.quote.value}`
    }
  }, [options, objectLabels.quote.value])

  const showChat =
    view !== 'work-order' &&
    view !== 'product-order-form' &&
    !silent &&
    getFeature({ options, path: 'quotes.conversations.enabled' }) &&
    quote.contact &&
    quote.owner &&
    !pdf

  const customerView = view !== 'work-order' && view !== 'product-order-form'

  const defaultPresentation = getDefaultPresentation({
    options,
    invoice: quote?.is_invoice,
    view: view as 'work-order' | 'product-order-form'
  })

  if (!!payment && pdf) {
    return <PaymentReceipt payment={payment} />
  }

  return (
    <>
      {doHeartbeat && <QuoteHeartbeat />}
      {doIdleTimeout && (
        <IdleTimeout onTimeout={() => setPauseHeartbeat(true)} onResume={() => setPauseHeartbeat(false)} />
      )}

      {!isQuoteExpired && (
        <PresentationNavContextProvider
          open={navOpen}
          onScroll={setCurrentPage}
          currentPage={currentPage}
          view={view}
          revision={revision}
          isResponding={responding}
          onPageClick={handlePageClick}
          onSectionClick={handleSectionClick}
          onMoreClick={handleMoreClick}
          onAcceptClick={handleAcceptClick}
          onPayClick={handlePayClick}
          onOpen={() => setNavOpen(true)}
          onClose={() => setNavOpen(false)}
        >
          <div className={classes.root}>
            <Hidden xlUp={true}>
              <PresentationNavMobile classes={{}} />
            </Hidden>
            <Hidden lgDown={true}>
              <PresentationNavFull />
            </Hidden>

            <div className={classes.mainLayout}>
              {view !== 'product-order-form' && (
                <Hidden lgDown={true}>
                  <div className={classes.sideNavWrapper}>
                    <PresentationNavSide classes={{ root: classes.sideNav }} />
                  </div>
                </Hidden>
              )}
              <div className={classes.presentationWrapper}>
                {revision && view === 'quote' && (
                  <Alert
                    content={`This is a historical version of the ${objectLabels.quote.value}.`}
                    severity="info"
                    className={classes.alert}
                  />
                )}
                {view === 'quote' && <PaymentRequests />}

                <Presentation
                  view={view}
                  stacked={stacked}
                  presentation={pdf ? defaultPresentation : undefined}
                  // filterTypes={pdf ? ['quote', 'work-order', 'product-order-form', 'pictures', 'terms'] : null}
                  page={stacked ? null : currentPage}
                  classes={{}}
                  isCustomerView={customerView}
                />
                {showChat && <ChatButton />}
                {!pdf && <Footer />}
              </div>
            </div>
          </div>
        </PresentationNavContextProvider>
      )}
      {isQuoteExpired && (
        <div className={classes.root}>
          <ErrorMessage
            title="Whoops"
            description={`Your ${objectLabels.quote.value} has expired. Please contact your estimator`}
          />
          <ChatButton />
        </div>
      )}
    </>
  )

  function handlePageClick(ev: React.MouseEvent, key: string) {
    setCurrentPage(key)
    setNavOpen(false)
    if (presentation?.advanced?.infiniteScroll) {
      const firstSection = document.getElementById(key)
      if (firstSection) {
        const sectionOffset = firstSection?.offsetTop ?? 0

        const spacing = theme.spacing(4)

        const header = document.getElementsByTagName('header')
        const headerHeight = header?.[0]?.offsetHeight ?? 0

        window.scrollTo({ top: sectionOffset - headerHeight - spacing, behavior: 'smooth' })
      }
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  function handleSectionClick(ev: React.MouseEvent, key: string) {
    setNavOpen(false)
    const section = document.getElementById(key)
    const sectionOffset = section?.offsetTop ?? 0

    const spacing = theme.spacing(4)

    const header = document.getElementsByTagName('header')
    const headerHeight = header?.[0]?.offsetHeight ?? 0

    window.scrollTo({ top: sectionOffset - headerHeight - spacing, behavior: 'smooth' })
  }

  function handleMoreClick(ev: React.MouseEvent, action: string) {
    setNavOpen(false)
    if (action === 'decline') {
      declineQuote({ quote })
    } else if (action === 'print' && !!window?.print) {
      window.print()
    } else if (action === 'download') {
      downloadQuote(quote)
    }
  }

  function handleAccept() {
    // Need to manually check the pending table to see if additional work with no cost needs to be accepted
    const hasPendingItems =
      getItems({
        quote,
        section: 'pending',
        view: 'area',
        consumer: 'customer',
        options,
        overrideOptions: { showPrices: true }
      }).length > 0

    const hasPending =
      (quote.is_invoice || quote.status.value === 'accepted') &&
      (!!((quote?.totals?.pending?.materials ?? 0) + (quote?.totals?.pending?.price ?? 0)) || hasPendingItems)

    onAccept(hasPending, quote)
  }

  function handleAcceptClick(_event: any, _: string) {
    handleAccept()
  }

  function handlePayClick(ev: React.MouseEvent, amount: number, payrix?: boolean) {
    const PaymentDialog = payrix ? CollectPayrixPaymentDialog : CollectStripePaymentDialog

    let invoicePaymentType: PayrixPaymentType
    if (allowPaymentsOnInvoices && allowACHPaymentsOnInvoices) {
      invoicePaymentType = 'both'
    } else if (allowPaymentsOnInvoices) {
      invoicePaymentType = 'credit'
    } else if (allowACHPaymentsOnInvoices) {
      invoicePaymentType = 'ach'
    }

    openDialog(PaymentDialog, {
      source: 'customer-view',
      quote,
      options,
      companyId: clientId,
      isDeposit: amount !== quote.totals.balance_due,
      amount: amount || quote.totals.balance_due,
      checkForMismatch: true,
      paymentType: quote.is_invoice ? invoicePaymentType : allowedDepositTypes,
      onCancel: dismissDialog,
      onConfirm: () => {
        window.location.reload()
      }
    })
  }
}
